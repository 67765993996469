<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex gap-1 w-full text-lg">
          <span>Edit account -</span>
          <span class="font-bold">{{ account.User }}</span>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th colspan="2">Account details</th>
            </tr>
          </thead>
          <tbody v-if="accountReady">
            <tr>
              <td>Login</td>
              <td>
                <b-form-input
                  v-if="accountNew"
                  v-model="account.User"
                  placeholder="Login"
                  size="sm"
                  autocomplete="off"
                  trim
                  :disabled="isLoading"
                ></b-form-input>
                <span v-else>
                  {{ account.User }}
                </span>
              </td>
            </tr>

            <tr>
              <td>Password</td>
              <td>
                <b-form-input
                  v-model="account.Pass"
                  placeholder="Password"
                  size="sm"
                  autocomplete="off"
                  trim
                  :disabled="isLoading"
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Name</td>
              <td>
                <b-form-input
                  v-model="account.Name"
                  placeholder="Name"
                  size="sm"
                  autocomplete="off"
                  trim
                  :disabled="isLoading"
                ></b-form-input>
              </td>
            </tr>

            <tr>
              <td>Group</td>
              <td>
                <b-form-select
                  v-model="account.GroupId"
                  :options="options.GroupId"
                  size="sm"
                  text-field="Name"
                  value-field="Id"
                  :disabled="isLoading"
                />
              </td>
            </tr>

            <tr v-if="account.GroupId !== 0">
              <td class="align-top">Roles</td>
              <td>
                <div class="flex flex-col gap-2">
                  <b-button
                    v-for="permit in options.Permits"
                    :key="`permit_${permit.Id}`"
                    :variant="getPermitVariant(permit)"
                    size="sm"
                    class="w-100"
                    @click="togglePermit(permit)"
                  >
                    <span>{{ permit.Name }}</span>
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">
                Loading account..
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div class="flex gap-4">
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="saveAccount()"
            >
              Save
            </b-btn>
          </div>
          <div class="flex gap-4">
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'UserAccountModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.account.User.length === 0) {
        return false;
      }
      if (this.account.Pass.length === 0 && this.account.GroupId === 0) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      account: {
        User: '',
        Pass: '',
        GroupId: null,
        Name: '',
        Permits: [],
      },
      accountReady: false,
      accountNew: false,
      options: {
        GroupId: [],
        Permits: [],
      },
    };
  },
  methods: {
    hasPermit(permit) {
      return this.account.Permits.includes(permit.Id);
    },
    getPermitVariant(permit) {
      if (this.hasPermit(permit)) {
        return 'primary';
      }
      return 'light';
    },
    togglePermit(permit) {
      if (this.hasPermit(permit)) {
        this.deletePermit(permit);
      } else {
        this.addPermit(permit);
      }
    },
    addPermit(permit) {
      this.account.Permits.push(permit.Id);
    },
    deletePermit(permit) {
      this.account.Permits = this.account.Permits.filter((item) => item !== permit.Id);
    },
    fetchGroups() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'groups' })
        .then((res) => {
          this.options.GroupId = [
            { Id: null, Name: '' },
            ...res.body,
          ];
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchPermits() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'permits' })
        .then((res) => {
          this.options.Permits = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAccount() {
      if (!this.data.username) {
        this.accountReady = true;
        this.accountNew = true;
        return;
      }

      this.loadingCount++;
      this.$http
        .get(`/users/${this.data.username}`)
        .then((res) => {
          this.account = res.body.user;
          this.accountReady = true;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    saveAccount() {
      this.loadingCount++;
      this.$http
        .post(`/users/${this.account.User}`)
        .send({ data: this.account })
        .then((res) => {
          if (res.body.session) {
            this.$store.state.account = Object.assign(this.$store.state.account, res.body.session);
          }
          this.$emit('saved', res.body.user);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchPermits();
      this.fetchGroups();
      this.fetchAccount();
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
